import Vue from 'vue'
import axios from '@axios'
// import dayjs from 'dayjs'

import moment from 'moment'

Vue.prototype.moment = moment

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},

  actions: {
    fetchTasks() {
      // const date = moment(new Date()).format('YYYY-MM-DD')

      return new Promise((resolve, reject) => {
        axios
          .get('/todo', { params: { date: '2022-01-10' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/todo/tasks', { task: taskData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/todo/tasks/${task.id}`, { task })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/todo/tasks/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
